<template>
  <div>
    <div class=" white radius-15">
      <degrees :isComplete="true"></degrees>
      <!-- <div class="mt-10 d-flex align-center justify-space-between">
        <span class="blue--text cursor-pointer font-20">Skip This Step</span>
        <v-btn rounded color="register-primary" dark elevation="0"
          ><span class="white--text">Edit </span></v-btn
        >
      </div> -->
      <v-form @submit.prevent="$emit('nextHandler',4)">
        <dialog-btns
          :btnTitle="$t('forms.saveContinue')"
          :closeTitle="''"
        ></dialog-btns>
      </v-form>
    </div>
  </div>
</template>

<script>
import DialogBtns from "../../../dashboard/components/dialogs/DialogBtns.vue";
import Degrees from "../../../dashboard/views/profile/degrees/Index.vue";
export default {
  components: {
    Degrees,
    DialogBtns,
  },
};
</script>

<style></style>
